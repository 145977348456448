<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>订单列表</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="toolsbar">
        <div style="float: left;margin-left: 20px;margin-top: 10px;">
          <a-radio-group button-style="solid" default-value="1" @change="ontypeChanges">
            <a-radio-button value="1">
              购买Vip
            </a-radio-button>
            <a-radio-button value="2">
              购买单独文章
            </a-radio-button>
            <a-radio-button value="3">
              升级客户
            </a-radio-button>
            <a-radio-button value="4">
              购买课程
            </a-radio-button>
          </a-radio-group>


          <a-button :loading="loading" style="margin-left: 20px" type="primary" @click="start">
            刷新
          </a-button>
        </div>
                <div class="serach_input">
                  <a-radio-group button-style="solid" default-value="-1" @change="onstatusChanges">
                    <a-radio-button value="-1">
                      待审核
                    </a-radio-button>
                    <a-radio-button value="1">
                      已经通过
                    </a-radio-button>
                    <a-radio-button value="2">
                      失败
                    </a-radio-button>
                    <a-radio-button value="3">
                      已经上传证书
                    </a-radio-button>
                  </a-radio-group>
                </div>
      </div>

      <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
        <!--                                {{data}}-->

        <a-table
            :columns="columns"
            :dataSource="orderdata"
            :loading="loading"
            :pagination="pagination"
            bordered
            rowKey="id"
            @change="onChange"
        >
          <template slot="status" slot-scope="text, index" style="text-align: center">
            <a-tag v-if="index.status === -1" :color="'green'"><span>待审核</span></a-tag>
            <a-tag v-if="index.status === 1" :color="'blue'">已通过</a-tag>
            <a-tag v-if="index.status === 2" :color="'cyan'">失败</a-tag>
            <a-tag v-if="index.status === 3" :color="'cyan'">已上传证书</a-tag>
          </template>
          <template slot="created_at" slot-scope="text, index" style="text-align: center">
            {{ index.created_at | dateFormat('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template slot="pay_type" slot-scope="text, index" style="text-align: center">
            <a-tag v-if="text === 1" :color="'green'"><span>购买网站vip</span></a-tag>
            <a-tag v-if="text === 2" :color="'blue'">购买文章</a-tag>
            <a-tag v-if="text === 3" :color="'cyan'">考试</a-tag>
            <a-tag v-if="text === 4" :color="'cyan'">参加活动/课程</a-tag>
          </template>

          <template slot="operation" slot-scope="text, index">
                            <span style="margin-right: 5px;">
                              <a @click="viewModal(index)">查看</a>
                            </span> <span style="margin-right: 5px;">
                              <a @click="showModal(index)">编辑</a>
                            </span>
            <span>
                              <a @click="deleModal(index)">剔除</a>
                            </span>
          </template>

        </a-table>
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>
    <a-modal v-model="popview" title="查看详情" width="70%" @ok="checkOrderok">
      <a-descriptions title="订单详细信息" bordered>
        <a-descriptions-item label="订单编号">
          {{order_info.pay_code}}
        </a-descriptions-item>
        <a-descriptions-item label="订单类型">
          <a-tag v-if="order_info.pay_type === 1" :color="'green'"><span>购买网站vip</span></a-tag>
          <a-tag v-if="order_info.pay_type === 2" :color="'blue'">购买文章</a-tag>
          <a-tag v-if="order_info.pay_type === 3" :color="'cyan'">考试</a-tag>
          <a-tag v-if="order_info.pay_type === 4" :color="'cyan'">参加活动/课程</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="订单状态">
          <a-badge status="processing" text="待审核" v-if="order_info.status === -1" />
          <a-badge status="success" text="已通过" v-if="order_info.status === 1"  />
          <a-badge status="error" text="失败" v-if="order_info.status === 2"  />
          <a-badge status="warning" text="已上传证书" v-if="order_info.status === 3"  />
        </a-descriptions-item>

        <a-descriptions-item label="账户">
          {{order_info.account_name}}
        </a-descriptions-item>
        <a-descriptions-item label="工作单位">

        </a-descriptions-item>
        <a-descriptions-item label="工作职位">

        </a-descriptions-item>
        <a-descriptions-item label="订单支付时间" :span="2">
          {{order_info.pay_time}}
        </a-descriptions-item>
        <a-descriptions-item label="订单金额">
          {{order_info.money}}
        </a-descriptions-item>
        <a-descriptions-item label="凭证信息" :span="3"  class="piao">
          <img v-if="order_info.bank_bills" :src="order_info.bank_bills" alt="imgs"/>
        </a-descriptions-item>
        <a-descriptions-item label="是否通过" :span="3" >
          <a-radio-group default-value="1" button-style="solid"  @change="onChanges" :disabled="order_info.status===1">
            <a-radio-button value="1">
              通过
            </a-radio-button>
            <a-radio-button value="2">
              不通过
            </a-radio-button>

          </a-radio-group>
        </a-descriptions-item>
        <a-descriptions-item label="审核备注" :span="3" >
          <a-textarea
              v-model="order_info.failure_content"
              placeholder="备注信息"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              :disabled="order_info.status===1"
          />
        </a-descriptions-item>

      </a-descriptions>
      {{ order_info }}
    </a-modal>

  </a-layout>

</template>

<script>
import Config from "@/config";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: "5%",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "订单号",
    dataIndex: "pay_code",
    width: "20%",
    scopedSlots: {customRender: "pay_code"}
  },
  {
    title: "用户昵称",
    dataIndex: "account_name",
    scopedSlots: {customRender: "account_name"}
  }, {
    title: "来源",
    dataIndex: "pay_type",
    width: "10%",
    scopedSlots: {customRender: "pay_type"}
  },{
    title: "备注",
    dataIndex: "course_title",
    width: "10%",
    scopedSlots: {customRender: "course_title"}
  },


  {
    title: "创建时间",
    dataIndex: "created_at",
    width: "15%",
    scopedSlots: {customRender: "created_at"}
  }, {
    title: "订单状态",
    dataIndex: "status",
    width: "5%",
    scopedSlots: {customRender: "status"}
  },

  {
    title: '操作',
    width: "12%",
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

// import {Modal} from "ant-design-vue";
import Storage from "../common/storage";
// import moment from "moment";
// import * as Util from "../common/util";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      columns,
      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
      orderdata: [],

      type_id: '',
      order_id: '',
      order_info: '',
      Orderstatus: '1',
      Search_status: "",
      pagination: {
        pageSize: 10
      },
      popinfos: false,
      popview: false,
      popadd: false,
      search_text: "",
      loading: false,
    };
  },
  created() {
    console.log("type_id", this.$route.query.type_id);
    if (this.$route.query.type_id !== undefined) {
      this.type_id = this.$route.query.type_id
    } else {
      this.type_id = "1"
    }
    console.log("type_id", this.type_id);
    this.doSearch(1, this.pagination.pageSize, this.Search_status, this.type_id);
  },
  methods: {
    // handleChange(info) {
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    //     // console.log(language);
    //   }
    //   if (info.file.status === 'done') {
    //     this.$message.success(`${info.file.name} file uploaded successfully`);
    //   } else if (info.file.status === 'error') {
    //     this.$message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
    beforeUploadvideo(file) {
      console.log("filetype", file.type);
      console.log("filesize", file.size / 1024 / 1024);
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      // if (!isJpgOrPng) {
      //   this.$message.error('You can only upload JPG file!');
      // }
      const isvideo = file.type === 'video/mp4';
      if (!isvideo) {
        this.$message.error('只能接收mp4格式的订单文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 600;
      if (!isLt2M) {
        this.$message.error('不能上传大于600M的订单!');
      }
      return isvideo && isLt2M;
    },
    beforeUploaditem(file) {
      console.log("filetype", file);
      console.log("filetype", file.type);
      console.log("filesize", file.size / 1024 / 1024);
      var index = file.name.lastIndexOf(".");
      var ext = file.name.substr(index + 1);

      const isJpgOrPng = ext === 'vtt' || ext === 'str';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload vtt or str file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },

    handleChange(info) {

      console.log("info", info);
      // console.log("lang", lang);
      console.log(info.file.status);
      this.fileList = info.fileList;
      for (var i = 0; i < this.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.edit_Article.cover_image = this.fileList[i].response.src;
        }
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      // console.log(this.edit_Article.cover_image);
    },
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch(1, this.pagination.pageSize, this.Search_status, this.type_id);
    },

    async onSearch(text) {
      this.data = []
      this.search_text = text;
      this.doSearch(1, this.pagination.pageSize, this.Search_status, this.type_id);
    },

    async onChange(pagination) {
      await this.doSearch(
          pagination.current,
          pagination.pageSize, this.Search_status, this.type_id
      );
    },

    async ontypeChanges(e) {
      console.log(e.target.value);
      this.type_id=e.target.value
      this.doSearch(1, this.pagination.pageSize, this.Search_status, this.type_id);
    },
    async onstatusChanges(e) {
      console.log(e.target.value);
      this.Search_status=e.target.value
      this.doSearch(1, this.pagination.pageSize, this.Search_status, this.type_id);
    },
    async onChanges(e) {
      this.Orderstatus=e.target.value
      console.log(e.target.value);
    },

    viewModal(item) {
      console.log(item)
      this.getinfo(item.id);

      this.popview = true;

    },


    async doSearch(page, limit, status, type) {
      this.loading = true;
      this.orderdata = [];
      console.log(Storage.uid)
      console.log(Storage.token)
      let now = Date.now();
      let result = await this.$get("/api/admin/order/list", {
        params: {
          token: Storage.token,
          uid: Storage.uid,
          page: page,
          limit: limit,
          status: status,
          type: type,
          t: now,
        }
      });

      if (result.status === true) {
        // let data = [...this.data];
        // for (let i in result.data.list) {
        //   let info = result.data.list[i];
        //   let offset = (parseInt(page) - 1) * limit + parseInt(i);
        //   // console.log(offset)
        //
        //   data[offset] = {
        //     uid: info.uid,
        //     name: info.name,
        //     group_name: info.group_name,
        //     login_ip: info.login_ip,
        //     login_name: info.login_name,
        //     login_time: moment(info.login_time * 1000).format("YYYY/MM/DD"),
        //     // login_time: info.login_time,
        //     mobile: info.mobile,
        //     nike_name: info.nike_name,
        //     sex: info.sex,
        //     status: info.status,
        //   };
        // }

        let pagination = {...this.pagination};
        pagination.total = result.data.total;
        this.pagination = pagination;
        this.orderdata = result.data.list;
        this.loading = false;
      }

      this.loading = false;
    },
    async getinfo(order_id) {
      this.loading = true;
      let now = Date.now();
      let result = await this.$get("/api/admin/order/info", {
        params: {
          token: Storage.token,
          uid: Storage.uid,
          id: order_id,
          t: now,
        }
      });

      if (result.status === true) {
        console.log(result.data);
        this.order_info = result.data
        this.loading = false;
      }

      this.loading = false;
    },
    async checkOrderok() {
      if(this.order_info.status ===1){
        this.popview = false;
          return;
      }
      this.loading = true;
      let now = Date.now();
      let result = await this.$post("/api/admin/order/checkOrder", {

          token: Storage.token,
          uid: Storage.uid,
          id: this.order_info.id,
          save_value:{
            status: this.Orderstatus,
            failure_content: this.order_info.failure_content,
          },
          t: now,

      });

      if (result.status === true) {
        console.log(result.data);
        this.order_info = result.data
        this.popview = false;
        this.doSearch(1, this.pagination.pageSize, this.Search_status, this.type_id);
        this.loading = false;
      }

      this.loading = false;
    },

  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 35vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}

.codes {
  padding: 10px;
  border: 1px dotted #1890ff;
}

/deep/ .ant-modal-body .playesvideo {
  text-align: center;
}
</style>
